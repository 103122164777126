import './section-divider.scss';
import React from "react";
import classNames from 'classnames';


export class SectionDivider extends React.Component<{}, { hasLoaded: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = {
      hasLoaded: false,
    }
    this.load = this.load.bind(this);
    this.unload = this.unload.bind(this);
  }

  load() {
    setTimeout(() => {
      this.setState({
        hasLoaded: true,
      });
    }, 200);
  }

  unload() {
    this.setState({
      hasLoaded: false,
    });
  }

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.unload();
  }

  render() {
    const classes = classNames('section-divider', {
      'loaded': this.state.hasLoaded,
    });

    return <div className={classes} />
  }
}
