import React from "react";
import { PrismicImage, PrismicRichText } from "../../utils/queries/types";
import RichTextRender from "../../utils/rich-text";

export const Quote = ({
  quote_text,
  quote_subcopy,
  quote_image,
}: {
  quote_text?: PrismicRichText;
  quote_subcopy?: PrismicRichText;
  quote_image: PrismicImage;
}) => {
  return (
    <section className="quote">
      <div className="container">
        <div className="flex-wrap flex justify-center items-center">
          <div className="col-10 text-left">
            <div className="description">{RichTextRender(quote_text)}</div>
          </div>
          {quote_image && (
            <div className="col-10 flex justify-start img-wrap">
              <img src={quote_image.url} alt={quote_image.alt} />
              <div className="quote-text">
                <span className="company">{RichTextRender(quote_subcopy)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
