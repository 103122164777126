import "./page-header.scss";
import classNames from "classnames";
import React from "react";
import { SectionDivider } from "../section-divider/section-divider";
import { PrismicImage, PrismicRichText } from "../../utils/queries/types";
import RichTextRender from "../../utils/rich-text";

export const PageHeaderCustom: React.FC<{
  image?: PrismicImage | React.ReactNode;
  hasSubnav?: boolean;
}> = ({ image, children, hasSubnav = false }) => {
  const wrapperClasses = classNames("page-header avenir bold", {
    "has-subnav": hasSubnav,
  });

  let imageContent = null;
  if (image) {
    if (image.hasOwnProperty("url")) {
      // image is PrismicImage;
      imageContent = <img src={image.url} alt={image.alt} />;
    } else {
      imageContent = image;
    }
  }

  return (
    <div className={wrapperClasses}>
      <div className="container">
        <div className="row flex justify-center">
          <div className="col-12 col-md-10">
            <div className="row">
              <div className="block lg:hidden col-12 mb-5">{imageContent}</div>
              <div className="col-12 col-lg-12 col-xl-7">{children}</div>
              <div className="hidden lg:block col-5">{imageContent}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageHeaderCustomV2: React.FC<{
  image?: PrismicImage | React.ReactNode;
  hasSubnav?: boolean;
}> = ({ image, children, hasSubnav = false }) => {
  const wrapperClasses = classNames("page-header-v2 avenir bold", {
    "has-subnav": hasSubnav,
  });

  let imageContent = null;
  if (image) {
    if (image.hasOwnProperty("url")) {
      // image is PrismicImage;
      imageContent = <img src={image.url} alt={image.alt} />;
    } else {
      imageContent = image;
    }
  }

  return (
    <div className={wrapperClasses}>
      <div className="container">
        <div className="row flex justify-center">
          <div className="col-12 col-lg-10">
            <div className="row">
              <div className="block lg:hidden col-12 mb-5">{imageContent}</div>
              <div className="col-12 col-md-7">{children}</div>
              <div className="hidden lg:block col-5">{imageContent}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageHeaderCustomV2Grey: React.FC<{
  image?: PrismicImage | React.ReactNode;
  hasSubnav?: boolean;
}> = ({ children, hasSubnav = false, image }) => {
  const wrapperClasses = classNames(
    "px-4 page-header-v2 page-header-v2--grey avenir bold",
    {
      "has-subnav": hasSubnav,
    }
  );

  let imageContent = null;
  if (image) {
    if (image.hasOwnProperty("url")) {
      // image is PrismicImage;
      imageContent = (
        <img className="lg:mx-auto" src={image.url} alt={image.alt} />
      );
    } else {
      imageContent = image;
    }
  }

  return (
    <div className={wrapperClasses}>
      <div className="container">
        <div className="row">
          <div className="block lg:hidden col-12 mb-5">{imageContent}</div>
          <div className="col-12 col-md-6 offset-md-1 pl-0">{children}</div>
          <div className="hidden lg:block col-5">{imageContent}</div>
        </div>
      </div>
    </div>
  );
};

export const PageHeaderCustomV2WhiteWide: React.FC<{
  image?: PrismicImage | React.ReactNode;
  hasSubnav?: boolean;
}> = ({ image, children, hasSubnav = false }) => {
  const wrapperClasses = classNames(
    "page-header-v2 page-header-v2--white avenir bold",
    {
      "has-subnav": hasSubnav,
    }
  );

  let imageContent = null;
  if (image) {
    if (image.hasOwnProperty("url")) {
      // image is PrismicImage;
      imageContent = <img src={image.url} alt={image.alt} />;
    } else {
      imageContent = image;
    }
  }

  return (
    <div className={wrapperClasses}>
      <div className="container">
        <div className="row flex justify-center">
          <div className="col-12 col-lg-10">
            <div className="row">
              <div className="block lg:hidden col-12 mb-5">{imageContent}</div>
              <div className="col-12 col-lg-6 col-md-12">{children}</div>
              <div className="hidden lg:block col-5">{imageContent}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type PageHeaderParams = {
  image?: PrismicImage | React.ReactNode;
  header: string;
  subheader: string | PrismicRichText;
  hasSubnav?: boolean;
  hasSectionDivider?: boolean;
  withSiteName?: boolean;
  preHeader?: string;
  component?: React.FC;
};
export const PageHeaderStandard = ({
  image,
  hasSectionDivider = true,
  header,
  subheader,
  hasSubnav,
  withSiteName = false,
  preHeader,
  component,
}: PageHeaderParams) => {
  const shRendered = RichTextRender(subheader);
  const bottomRendered =
    typeof subheader === "string" ? (
      <p className="avenir">{shRendered}</p>
    ) : (
      shRendered
    );

  preHeader = preHeader || "amazee.io";
  const siteHeaderRendered = withSiteName ? (
    <div className="site-name">{preHeader}</div>
  ) : null;

  const Component: typeof PageHeaderCustom = component || PageHeaderCustom;

  /* If header is the only child with already an h1 then dont wrap it with an h1 tag */
  const wrapHeader =
    header &&
    header.props &&
    header.props.children &&
    header.props.children.length === 1 &&
    header.props.children[0].type === "h1";

  return (
    <Component hasSubnav={hasSubnav} image={image}>
      <div className="top">
        {siteHeaderRendered}

        {!wrapHeader ? <h1 className="avenir">{header}</h1> : header}
      </div>
      {hasSectionDivider ? <SectionDivider /> : null}
      <div className="bottom">{bottomRendered}</div>
    </Component>
  );
};

export const PageHeaderh2 = ({
  image,
  hasSectionDivider = true,
  header,
  subheader,
  hasSubnav,
  withSiteName = false,
  preHeader,
  component,
}: PageHeaderParams) => {
  const shRendered = RichTextRender(subheader);
  const bottomRendered =
    typeof subheader === "string" ? (
      <p className="avenir">{shRendered}</p>
    ) : (
      shRendered
    );

  preHeader = preHeader || "amazee.io";
  const siteHeaderRendered = withSiteName ? (
    <div className="site-name">{preHeader}</div>
  ) : null;

  const Component: typeof PageHeaderCustom = component || PageHeaderCustom;

  return (
    <Component hasSubnav={hasSubnav} image={image}>
      <div className="top blue-strong">
        {siteHeaderRendered}
        <h2 className="avenir">{header}</h2>
      </div>
      <div className="bottom blue-strong">{bottomRendered}</div>
    </Component>
  );
};
